import { createStore, createHook } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';

import type { EmojiDescription } from '@atlaskit/emoji/types';

import { sanitizeEmojiValue } from './transformer';
import type { EmojiComponentProps } from './__types__/EmojiComponent';
import { getEmojiFromEmojiId } from './emojiMutationHelpers';
import type { GetEmojiArgs } from './emojiMutationHelpers';

type Actions = typeof actions;
type SelectorProps = EmojiComponentProps['emoji'];
type SelectorState = {
	emojiDescription?: EmojiDescription | null;
	error?: any;
};
type State = Record<string, SelectorState>;

const actions = {
	loadEmojiDescription:
		(args: GetEmojiArgs): Action<State, void, void> =>
		({ getState, setState }) => {
			/**
			 * Helper function to update the emoji in the cache.
			 * Maps the emoji's id string to the emoji description, as well as metadata about the promise state.
			 */
			const { emojiId } = args;
			const updateEmojiDescriptionState = (emojiDescriptionState: SelectorState) => {
				setState({
					...getState(),
					[emojiId]: emojiDescriptionState,
				});
			};

			getEmojiFromEmojiId(args)
				.then((emojiDescription: EmojiDescription | null | undefined) => {
					updateEmojiDescriptionState({ emojiDescription });
				})
				.catch((error) => {
					updateEmojiDescriptionState({ error });
				});
		},
};

const EmojiStore = createStore<State, Actions>({
	initialState: {},
	actions,
	name: 'EmojiStore',
});

/**
 * This sweet-state selector function is an important optimization. The useEmojiDescription hook only will rerender when
 *   1. the emoji prop changes, or
 *   2. the result of this function changes
 * This way, we prevent a single cache update (e.g. the successful fetch of an emojiDescription) from rerendering every emoji.
 */
const getEmojiDescription = (state: State, emoji: SelectorProps): SelectorState => {
	// In this case, the caller already has an EmojiDescription, so we just return it.
	if (typeof emoji === 'object') {
		return { emojiDescription: emoji };
	}
	// Try to get the emoji from the cache
	const emojiId = sanitizeEmojiValue(emoji);
	if (emojiId in state) {
		return state[emojiId];
	}
	return {};
};

export const useEmojiDescription = createHook<State, Actions, SelectorState, SelectorProps>(
	EmojiStore,
	{
		selector: getEmojiDescription,
	},
);
